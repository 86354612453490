<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>
  
            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->
  
        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->
  
            <!-- Page content -->
            <div id="page-content" class="add-edit-actividad-page">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/vehiculos']">Vehículos</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>
  
                <div class="block full break-padding">
                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">
                              
                            <div class="row">
                              <div class="col-lg-4 col-md-12">
                                <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image hide" (click)="SelecImagen()">
                                  <i class="icofont-camera-alt font-50"></i>
                                </div>
                                <div class="img-preview-container cursor relative hide" (click)="SelecImagen()">
                                  <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                  <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                                  <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                                </div>
                                <hr>
                                <h4 class="hide">Galería de imágenes</h4>
                                <div class="text-right hide" *ngIf="!data.imagenes || data.imagenes.length <= 20" style="margin-top: -30px;margin-bottom: 20px;">
                                  <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen a la galería<a href=""></a></a>
                                </div>
                                <div class="text-center hide" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                                  <h4>No hay imágenes aún</h4>
                                </div>
                                <div class="row gallery hide" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes" style="padding: 0px 10px;">
                                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id" style="padding-left:5px;padding-right:5px;">
                                      <div class="gallery-image-container animation-fadeInQuick2Inv" style="margin-bottom: 10px;">
                                        <img src="{{item.imagen}}" alt="Image">
                                        <a href="{{item.imagen}}" class="gallery-image-options" data-toggle="lightbox-image" title="Image Info">
                                          <h2 class="text-light" style="margin:0;font-size:12px"><strong>Ampliar</strong></h2>
                                          <i class="fa fa-search-plus fa-3x text-light" style="font-size:12px"></i>
                                        </a>
                                        <a class="btn btn-danger cursor absolute" style="top:0;right:0;" (click)="DelImagen($event, item)"><i class="fa fa-trash"></i></a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8 col-md-12">
                                <div class="row">
                                  <div class="col-md-5 col-xs-6">
                                    <div class="form-group">
                                      <label class="text-primary">Nombre *</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-6">
                                    <div class="form-group">
                                      <label class="text-primary">Tipo *</label>
                                      <select required name="provincia" class="form-control" [(ngModel)]="data.id_tipo">
                                        <option value="{{item.id}}" *ngFor="let item of tipos">{{item.tipo}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-2 col-xs-6">
                                    <div class="form-group">
                                      <label class="text-primary">Plazas *</label>
                                      <input type="number" class="form-control" [(ngModel)]="data.plazas">
                                    </div>
                                  </div>
                                  <div class="col-md-2 col-xs-6">
                                    <div class="form-group">
                                      <label class="text-primary">Distintivo *</label>
                                      <input type="color" class="form-control" [(ngModel)]="data.distintivo_color">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-6">
                                    <div class="form-group">
                                      <label style="cursor: pointer;"><input type="checkbox" [(ngModel)]="data.estado"/> Vehículo activo/Desactivo</label><br>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>
  
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
  </div>